
import React from "react";
import Layout from "../gatsby-theme-blog/components/layout.js"
import SEO from "../gatsby-theme-blog/components/seo"
import Footer from "../gatsby-theme-notes/components/footer"
import { Gallery } from "gatsby-theme-gallery";

export default ({...props}) => (
  <Layout {...props} location={props.location} title='pedro.fyi'>
    <SEO title="Photos"  favicon="camera"/>
    <Gallery />
    <Footer/>
  </Layout>
);
/** @jsx jsx */
import React, {useState, useEffect } from 'react';
import { Styled, css, jsx } from 'theme-ui';
import Img from 'gatsby-image';
import { Lightbox } from 'react-modal-image';
import useGallery from '../hooks/useGallery';
import Tile from 'gatsby-theme-gallery/src/components/Tile';

const imgStyles: any = {
  css: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    transition: 'transform 0.5s, filter 0.25s',
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'saturate(1.3)',
    },
  },
};

const useKeyPress = function(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};


const Gallery = () => {
  const images = useGallery();

  let visibleImages = images;
  const locations = ['all', 'azores', 'barcelona', 'cascais', 'chicago', 'costa vicentina', 'guimarães', 'lisbon', 'london', 'madrid', 'munich', 'oporto', 'santa cruz', 'santander', 'untagged']
  const [showImageIndex, setShowImageIndex] = React.useState<
    number | undefined
  >(undefined);
  const [location, setLocation] = useState('all');
  const [currentImage, setCurrentImage] = useState(undefined);

  const arrowRightPress = useKeyPress("ArrowRight");
  const arrowLeftPress = useKeyPress("ArrowLeft");

  useEffect(() => {
    if (arrowRightPress) {
      if(currentImage<images.length){
        setCurrentImage(currentImage+1)
        setShowImageIndex(currentImage+1);
      }


    }
  }, [arrowRightPress]);
  useEffect(() => {
    if (arrowLeftPress) {
      if(currentImage>0){
        setCurrentImage(currentImage-1)
        setShowImageIndex(currentImage-1);
      }
    }
  }, [arrowLeftPress]);

  const visible = (relativePath) => {
    if(relativePath.startsWith(location) || location == 'all'){
      return 'block';
    } else {
      return 'none';
    }
  }

  return (
    <div>
      <div sx={{
            paddingBottom: '25px',
        }}>
        {locations.map((location) => (
          <Styled.a sx={{
            background: 'none!important',
            border: 'none',
            padding: '10px',
            /*input has OS specific font-family*/
            textDecoration: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
        }} onClick={() => {
          setLocation(location);
          return false;
        }}>#{location} </Styled.a>
        ))}
      </div>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(8rem, 0.5fr))',
          gridAutoRows: '12rem',
          gridGap: '0.5rem',
          '@media (min-width: 560px)': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(10rem, 1fr))',
            gridAutoRows: '12rem',
          },
          '@media (min-width: 1280px)': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
            gridAutoRows: '12rem',
          }
      }}>
        {visibleImages.map((image, index) => (
          <Tile
            sx={{
              display: visible(image.relativePath),
          }}
            key={image.id}
            onClick={() => {
              setShowImageIndex(index);
              setCurrentImage(index);
            }}
          >
            <Img alt={image.name} fluid={image.fluid} {...imgStyles} />
          </Tile>
        ))}
      </div>
      {showImageIndex !== undefined && (
        <Lightbox
          hideDownload={true}
          large={images[showImageIndex].publicURL}
          onClose={() => {
            setShowImageIndex(undefined);
            setCurrentImage(undefined);
          }}
        />
      )}
    </div>
  );
};

export default Gallery;
